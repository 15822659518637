@media (max-width: 992px) {
  .sidebar-component {
    display: none;
  }
}
.bars {
  display: none;
}

.btnsidebar-component {
  display: none;
}

@media (max-width: 992px) {
  .btnsidebar-component {
    display: block;
  }
}

/* .App-Invesment {
  margin-top: 20px;
} */

.contaner-Invesment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3%;
}

.title-Invesment {
  font-size: 30px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .title-Invesment {
    margin-top: 10%;
    font-size: 23px;
    font-weight: 600;
  }
}

.img-Invesment {
  width: 55%;
}
@media (max-width: 480px) {
  .img-Invesment {
    margin-top: 2%;
    width: 100%;
  }
}
.text-logos {
  text-align: center;
  flex-wrap: wrap;
  font-size: 25px;
  padding-top: 10px;
  margin-left: 20px;
}
.get-starts {
  background-color: #003b57;
  color: white;
  text-align: center;
  border-radius: 10px;
  margin-top: 1%;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 3%;
  z-index: 1;
}
.get-starts:hover {
  color: white;
}
