span {
  font-size: 40px;
}

.container {
  display: flex;
  justify-content: flex-start;
  z-index: 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: "#022434" !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
  border-top: 1px solid #707070;
}

.slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #003b57;
}
input:focus + .slider {
  box-shadow: 0 0 1px #003b57;
}
input:checked + .slider::before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
}

/*Rounded slider */
.slider.round {
  border-radius: 34px;
  z-index: 1;
  font-size: 25px;
  color: #003b57;
  display: flex;
  text-align: center;
  background-color: "#022434" !important;
}
.slider.round::before {
  border-radius: 50%;
}

.switch-checkbox {
  padding: 10px;
}

.slider.round-la {
  border-radius: 34px;
  z-index: 1;
  font-size: 25px;
  color: white;
  font-size: 20px;
}
.slider.round-la::before {
  border-radius: 50%;
  background-color: #003b57;
}
.en {
  position: absolute;
  font-size: 15px;
  z-index: 3;
  margin-left: 8px;
  left: 0;
  top: 5px;
}
.ar {
  position: absolute;
  font-size: 15px;
  z-index: 3;
  margin-right: 5px;
  right: 0;
  top: 5px;
}

.toogle {
  background-color: #003b57;
  height: 35px;
  min-width: 70px;
  border-radius: 30px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  direction: ltr;
}
.tooglee {
  border: 1px solid #003b57;
  height: 35px;
  width: 70px;
  border-radius: 30px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  direction: ltr;
}
.ss {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding-bottom: 4px;
  padding-left: 1px;
}
.toogle-left {
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 20px;
  margin: 6px 5px 5px 3px;
}
.toogle-right {
  height: 23px;
  width: 23px;
  background-color: white;
  border-radius: 20px;
  margin: 6px 5px 5px 40px;
}

.toogle-rightt {
  height: 24px;
  width: 24px;
  background-color: #003b57;
  border-radius: 20px;
  margin: 4px 5px 5px 40px;
}
.toogle-leftt {
  height: 24px;
  width: 24px;
  background-color: #003b57;
  border-radius: 20px;
  margin: 4px 5px 5px 3px;
}
