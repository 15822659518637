.main-contaner {
  background-color: #d2dfe6;
  min-width: 240px;
  max-width: 240px;
  border-radius: 10px;
  opacity: 0.9;
  border-radius: 20px;
  text-align: center;
  min-height: 100%;
  position: fixed;
  top: 0;
}

.side_nav_1 {
  background-color: white;
  min-width: 100%;
  max-width: 100%;
  border-radius: 20px;
  box-shadow: -8px 0px 0px 10px #d2dfe6, 10px 5px 9px -5px #000;
}
.side_nav {
  background-color: #022434;
  min-width: 100%;
  max-width: 100%;
  border-radius: 20px;
  box-shadow: -8px 0px 0px 10px #011b35, 10px 5px 9px -5px #000;
}

.main-contaner .logo {
  max-width: 4%;
  margin-top: 10px;
}

.Overview-text {
  color: #d59020;
  padding-top: 1%;
  font-size: 25px;
}

.link {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}
.link.active {
  color: #dba42a !important;
}

.accordion-button::after {
  display: none !important;
}

.accordion-header {
  font-size: 20px;
}

button:not(:focus-visible) {
  background-color: inherit !important;
  box-shadow: none !important;
}

.rotatedis {
  transform: rotate(90deg);
}
