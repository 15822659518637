.hiegt-card {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.button-close {
  border: 1px solid #e53838;
  border-radius: 50%;
  color: #e53838;
  font-size: 20px;
  height: 30px;
}

.form-style {
  border-radius: 10px;
  padding: 1% 1% 3%;
}
.form-control {
  border-radius: 10px !important;
  border: 2px solid #0e4f6e !important;
  color: inherit !important;
}
.form-control:focus {
  box-shadow: none !important;
}
.form-select {
  border-radius: 10px !important;
  border: 2px solid #0e4f6e !important;
  color: #9b9999 !important;
}

div .New-attributie-btn {
  border: none;
  background-color: #0e4f6e !important;
  border-radius: 10px;
  color: white;
  text-decoration: none;
  text-align: center;
}

div .New-attributie-btn:hover {
  color: white;
}
.ss .New-attributie-btn {
  border: none;
  background-color: #0e4f6e !important;
  border-radius: 10px;
  color: white;
  text-decoration: none;
  text-align: center;
}

.ss .New-attributie-btn:hover {
  color: white;
}

.text-aeria {
  border-radius: 10px;
  border: 2px solid #0e4f6e !important;
  min-height: 300px;
}
.app-payload {
  border-radius: 20px;
  width: 100%;
}
.new-payload {
  border: none;
  color: inherit;
}

/* style for treeview node */
span.tree-view-node:first-child:before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  left: -15px;
  top: 0px;
  background-color: #ddd;
}
span.tree-view-node:last-child:before {
  content: "";
  width: 1px;
  height: 0%;
  position: absolute;
  left: -15px;
  top: 0px;
  background-color: #ddd;
}
span.tree-view-node span {
  padding-top: 10px;
  margin-left: 30px;
  position: relative;
  list-style: none;
  display: block;
}
/* style for treeview label */
.tree-view-label {
  cursor: pointer;
  padding: 5px 15px;
  display: inline-block;
  position: relative;
  background-color: #eee;
}
.tree-view-label:hover {
  background: rgba(0, 0, 0, 0.05);
}
.tree-view-label:before {
  content: "";
  width: 1px;
  height: 82%;
  position: absolute;
  left: -15px;
  top: -10px;
  background-color: #ddd;
}

.tree-view-label:after {
  content: "";
  width: 15px;
  height: 1px;
  position: absolute;
  left: -15px;
  top: 50%;
  background-color: #ddd;
}
.tree-view-label.active {
  background-color: #ddd;
  color: #333;
}
.tree-view-label.active:hover {
  background: #eee;
}

.label_img {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #0e4f6e !important;
  border-radius: 20px;
  min-width: 25rem;
  min-height: 20rem;
  cursor: pointer;
  font-size: larger;
}
@media (max-width: 480px) {
  .label_img {
    min-width: 15rem;
    min-height: 10rem;
  }
}
.input_img {
  display: none;
}

.App-img {
  max-width: 200px !important;
}

@media (max-width: 600px) {
  .App-img {
    max-width: 50px !important;
  }
}
