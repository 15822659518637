.app-card-features {
  display: flex !important;
  justify-content: start;
  flex-direction: row;
}

.card-features {
  background-color: #003b57 !important;
  color: white;
  width: 28% !important;
  height: 184px !important;
  margin-left: 5.2%;
  border-radius: 20px !important;
}
.card-features-white {
  background-color: inherit !important;
  border: 2px solid #0e4f6e !important;
  border-radius: 20px !important;
  width: 28% !important;
  height: 175px !important;
  margin-left: 5.2%;
}

.align-icon {
  text-align: center;
  font-weight: 700;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-icon-white {
  text-align: center;
  font-weight: 700;
  color: #0e4f6e;
}
div .btnclick {
  border: none;
  color: white;
  background-color: #0e4f6e !important;
  border-radius: 50%;
  padding: 3%;
}
div .btnclick-1 {
  border: none;
  color: white;
  background-color: #0e4f6e !important;
  border-radius: 30px;
  padding: 3% 9% 3% 9%;
}
div .btnclick-2 {
  border: none;
  color: white;
  background-color: #003b57 !important;
  position: absolute;
  left: 85%;
  bottom: 27%;
}

@media (max-width: 772px) {
  div .btnclick-2 {
    left: 80%;
  }
}

@media (max-width: 480px) {
  div .btnclick-2 {
    left: 85%;
  }
}

.link_new_feature {
  text-decoration: none;
  background-color: inherit !important;
  border: 2px solid #0e4f6e !important;
  border-radius: 20px !important;
  width: 28% !important;
  height: 175px !important;
  margin-left: 5.2%;
}
.card-color {
  color: white;
}
