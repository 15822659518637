.app-contract {
  display: flex;
}

@media (max-width: 600px) {
  .col-app {
    width: 90% !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.res {
  border: 5px solid #003b57;
  width: 50%;
  position: relative;
}
@media (max-width: 600px) {
  .res {
    width: 100%;
  }
}
.type-class {
  position: absolute;
  display: flex;
  justify-content: center;
  right: -4.2%;
  top: -1.5%;
}
.type-class-arabic {
  position: absolute;
  display: flex;
  justify-content: center;
  left: -4.2%;
  transform: rotateY(180deg);
  top: -1.5%;
}

@media (max-width: 600px) {
  .type-class {
    position: absolute;
    display: flex;
    justify-content: center;
    right: -9.2%;
  }
}

@media (max-width: 600px) {
  .type-class-arabic {
    position: absolute;
    display: flex;
    justify-content: center;
    left: -9.2%;
  }
}

@media (max-width: 600px) {
  .type-class {
    position: absolute;
    display: flex;
    justify-content: center;
    right: -9.2%;
  }
}

@media (max-width: 600px) {
  .type-class-arabic {
    position: absolute;
    display: flex;
    justify-content: center;
    left: -9.2%;
  }
}

@media (max-width: 480px) {
  .type-class {
    position: absolute;
    display: flex;
    justify-content: center;
    right: -12%;
  }
}

@media (max-width: 480px) {
  .type-class-arabic {
    position: absolute;
    display: flex;
    justify-content: center;
    left: -12%;
  }
}

.type-header {
  color: white;
  font-size: 25px;
  position: absolute;
  transform: rotateY(180deg);
}

.type-header-arabic {
  color: white;
  font-size: 25px;
  position: absolute;
}

.div-input {
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.copy-icon {
  position: absolute;
  border-radius: 5px;
  padding: 20px;
}
.copy-icon .copyy {
  background-color: #003b57 !important;
  color: white;
  border: none;
  padding: 7px;
  border-radius: 5px;
}

.input-radios {
  border-radius: 10px;
  border: 1px solid #003b57;
  width: 100%;
}

.payload {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .payload {
    display: flex;

    flex-wrap: wrap;
  }
}
@media (max-width: 600px) {
  .payloadss {
    width: 20px;
  }
}

.rate {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #003b57;
  font-size: 14px;
  color: white;
  border-radius: 15px;
  text-align: center;
  width: 25% !important;
}
.dir {
  direction: rtl;
}

.back-btn {
  width: 20%;
  padding: 1%;
  margin: 4%;
  border-radius: 10px;
  font-size: 20px;
}
@media (max-width: 600px) {
  .back-btn {
    width: 40%;
    padding: 1%;
    margin: 4%;
  }
}
.icon {
  font-size: 40px;
  margin-right: 10px;
  color: #003b57;
}
.icon-rotat {
  transform: rotateY(180deg);
  font-size: 40px;
  margin-left: 10px;
  color: #003b57;
}

@media (max-width: 600px) {
  .icon {
    font-size: 33px;
  }
  .icon-rotat {
    transform: rotateY(180deg);
    font-size: 33px;
  }
}

.back-text {
  font-size: 30px;
}
@media (max-width: 992px) {
  .back-text {
    font-size: 20px;
  }
}
.notes {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.tabel-app {
  color: inherit !important;
  text-align: center;
  border: 3px solid #003b57 !important;
}

.head {
  background-color: #003b57;
  color: white !important;
}

.tabel-app th {
  border-left: 3px solid #003b57;
  border-bottom: none;
}
.tabel-app td {
  border-left: 3px solid #003b57;
  border-bottom: 10px solid #003b57;
}

.app-notes {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
  white-space: pre-line;
  overflow-wrap: break-word;
}

@media (max-width: 600px) {
  .app-notes {
    width: 100% !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .rate {
    font-size: 14px;
    width: 100% !important;
  }
}

.payload .ratee {
  font-size: 20px;
}
@media (max-width: 600px) {
  .payload .ratee {
    font-size: 14px;
  }
}
