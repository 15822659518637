.logo {
  width: 38% !important;
}

@media (max-width: 480px) {
  .welcom-page {
    margin-top: 70px;
  }
}

.text-logo {
  text-align: center;
  flex-wrap: wrap;
  font-size: 25px;
  padding-top: 20px;
  margin-left: 20px;
}
@media (max-width: 600px) {
  .text-logo {
    font-size: 30px;
    margin-top: 10%;
  }
}
.get-start-btn {
  display: flex;
  justify-content: center;
}

.get-start {
  background-color: #003b57;
  color: white;
  text-align: center;
  border-radius: 10px;
  margin-top: 1%;
  width: 25%;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 1.2%;
  z-index: 1;
}

.get-start:hover {
  color: white;
}

@media (max-width: 600px) {
  .get-start {
    width: 60%;
    padding: 3%;
  }
}

@media (min-width: 600px) {
  .get-start {
    width: 25%;
    padding: 2%;
  }
}
