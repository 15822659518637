.card-dark-mode {
  background-color: #efefef !important;
  border-radius: 30px !important;
}
.card-light-mode {
  background-color: #022434 !important;
  border-radius: 30px !important;
}

.card-dark-mode-chiled {
  background-color: inherit;
  border: 1px solid #022434 !important;
}

.backyard {
  background-color: inherit !important;
  border: 1px solid #022434 !important;
  border-radius: 30px !important;
}
.backyard-chiled {
  border-bottom: 2px dashed #e53838;
}

.code-title-object {
  color: #dba42a;
}
