.our-project-text {
  font-size: 40px;
  font-weight: 600;
}

@media (max-width: 480px) {
  .our-project-text {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    margin-top: 15%;
  }
}

.img-project {
  width: 100%;
  position: relative;
  max-height: 150px;
}

.App-cards {
  width: 33%;
  height: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 480px) {
  .App-card {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
@media (max-width: 627px) {
  .App-cards {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .App-cards {
    width: 22%;
  }
}

.card {
  width: 90%;
  margin-bottom: 4.1%;
  border: none !important;
  direction: ltr;
  background-color: inherit !important;
}

@media (max-width: 480px) {
  .card {
    width: 100%;
    margin-bottom: 4.1%;
    direction: ltr;
  }
}

.card-body {
  background-color: white !important;
}

.link-card {
  margin-bottom: 12.4%;
  text-decoration: none;
}
.title-projext {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #003b57;
  color: white;
  text-align: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-top: 1px;
  height: 15%;
  font-size: 14px !important;
  width: 104%;
  position: absolute;
  bottom: 5%;
}
@media (max-width: 600px) {
  .title-projext {
    width: 102%;
    height: 20%;
  }
}

.svg-img {
  position: absolute;
  bottom: 20.6%;
  width: 14px;
  left: 99%;
}
@media (max-width: 992px) {
  .svg-img {
    width: 10px;
  }
}
@media (max-width: 768px) {
  .svg-img {
    width: 8px;
  }
}
@media (max-width: 600px) {
  .svg-img {
    width: 5px;
    bottom: 20.4%;
  }
}
@media (max-width: 480px) {
  .svg-img {
    position: absolute;
    bottom: 20.6%;
    width: 11px;
    left: 99%;
  }
}

.svg-img1 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 15%;
  cursor: pointer;
}

@media (max-width: 992px) {
  .svg-img1 {
    bottom: -25%;
  }
}
@media (max-width: 768px) {
  .svg-img1 {
    bottom: -50%;
  }
}
@media (max-width: 600px) {
  .svg-img1 {
    bottom: -30%;
  }
}
@media (max-width: 480px) {
  .svg-img1 {
    bottom: 20%;
  }
}

.card-dark {
  background-color: #022434 !important;
}

.add-new-project {
  background-color: inherit;
  border: 2px solid #0e4f6e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  text-decoration: none;
  padding: 3%;
  height: 150px;
}
.add-new-project:hover {
  color: inherit;
}
@media (max-width: 768px) {
  .add-new-project {
    padding: 4%;
  }
}

@media (max-width: 480px) {
  .add-new-project {
    width: 100%;
    padding: 20%;
  }
}
.ssss {
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  height: 70vh;
}

@media (max-width: 480px) {
  .ssss {
    display: block;
    justify-content: center;
    align-items: center;
    margin-top: 25%;
  }
}

.sss {
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: center;
  width: 75%;
  margin-left: 20px;
}

.add_one {
  display: flex;
  justify-content: center;
  align-items: end;
  width: 25%;
}
@media (max-width: 480px) {
  .add_one {
    margin-top: 25%;
    width: 100%;
  }
}
.btn-add-one {
  width: 70%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid #0e4f6e;
  color: inherit;
}

.row-remov {
  color: white;
  font-weight: 600;
}
.app-remov .btn-style {
  border: none;
  color: white;
  background-color: #003b57 !important;
  border-radius: 10px;
}
