.App-sidebare-togle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 480px) {
  .logo-white {
    width: 10%;
  }
}
.side-toggle {
  margin-top: 10px;
  margin-left: 10px;
  border: none;
  background-color: white;
}

.link-1 {
  color: inherit;
  text-decoration: none;
}
.link-1.active {
  color: #dba42a !important;
}

.link-1:hover {
  color: inherit;
}
.rotatedis {
  transform: rotate(90deg);
}

button:not(:focus-visible) {
  background-color: inherit !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: inherit !important;
}

div[role="navigation"] {
  display: inline !important;
  width: 50% !important;
}
