.swiper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  direction: ltr;
  z-index: 0 !important;
}
@media (max-width: 600px) {
  .swiper {
    margin-top: 20px;
    width: 90%;
  }
}
@media (max-width: 1060px) {
  .swiper {
    margin-top: 20px;
    width: 60%;
  }
}
.swiper .swiper-contaner {
  margin-top: 50px;
  width: 60%;
}
@media (max-width: 480px) {
  .swiper .swiper-contaner {
    margin-top: 50px;
    width: 100%;
  }
}
.swiper-contaner {
  height: 20%;
  padding: 42rem 0;
  position: relative;
}
.swiper-slide {
  width: 33% !important;
  height: 100% !important;
  border-radius: 2rem;
  object-fit: cover;
}
.swiper-slide img {
  width: 80%;
  height: 100%;
}

.slider-controler {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 35%;
  margin-right: 35%;
}

.swiper-pagination {
  position: relative;
  width: 15rem;
}
