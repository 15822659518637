.editor {
  position: relative;
}

.editor .Copy {
  border: none;
  background-color: rgb(39, 40, 34);
  font-size: 15px;
  color: white;
  position: absolute;
  right: 5%;
  bottom: 95%;
}
.Copy i {
  font-size: 10px;
}

.ace_paren {
  font-size: 14px !important;
}
.ace_content {
  font-size: 14px !important;
}
.ace_string {
  font-size: 14px !important;
}
.ace_boolean {
  font-size: 14px !important;
}
.ace_variable {
  font-size: 14px !important;
}
.ace_numeric {
  /* تنسيق الأرقام هنا */
  font-size: 14px !important;
  /* أي تعديلات أخرى */
}

.ace_punctuation {
  font-size: 14px !important;
}
.ace_gutter {
  font-size: 14px !important;
}
