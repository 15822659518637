.new-project {
  background-color: inherit;
  text-align: center;
  border: 2px solid #0e4f6e;
  border-radius: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
}
.new-project:hover {
  color: inherit;
}

@media (max-width: 992px) {
  .button-create {
    padding: 20px;
  }
}

div .create-btn {
  background-color: #0e4f6e !important;
  border: none;
  border-radius: 10px;
  color: white;
  width: 100%;
  text-decoration: none;
  text-align: center;
}
div .create-btn:hover {
  color: white;
}

.horizontal-line {
  position: relative;
  border-radius: 20px;
}

.horizontal-line::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  height: 4px;
  background-color: inherit !important;

  width: 10px;
}
.horizontal-line-1 {
  position: relative;
  border-radius: 20px;
}

.horizontal-line-1::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  height: 4px;
  background-color: inherit !important;

  width: 10px;
}
