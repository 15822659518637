.main {
  font-family: "Almarai", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Roboto", sans-serif;
  padding: 20px 20px 20px 20px;
  min-height: 100vh;
}
.arabic {
  font-family: "Almarai", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Roboto", sans-serif;
  padding: 20px 20px 20px 20px;
  min-height: 100vh;
  direction: rtl;
}

.dark-mode {
  background-color: #022434;
  color: white;
  min-height: 93vh;
  border-radius: 20px;
  position: relative;
}
.light-mode {
  background-color: #fff;
  color: #011b35;
  min-height: 93vh;
  border-radius: 20px;
  position: relative;
}

@media (max-width: 480px) {
  .dark-mode {
    background-color: #022434;
    color: white;
    min-height: 100vh;
    border-radius: 0px;
    position: relative;
  }
}

@media (max-width: 480px) {
  .light-mode {
    background-color: #fff;
    color: #011b35;
    min-height: 100vh;
    border-radius: 0px;
    position: relative;
  }
}

::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 12px;
}
::-webkit-scrollbar:hover {
  background-color: #011b35;
  width: 12px;
}

/* تحديد شكل الـ thumb */
::-webkit-scrollbar-thumb {
  background-color: #022434;
  border-radius: 20px;
  border: 3px solid #f5f5f5;
}

/* تحديد شكل الـ thumb عند التحريك */
::-webkit-scrollbar-thumb:hover {
  background-color: white;
}
