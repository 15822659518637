.text-title {
  font-size: 35px;
  font-weight: 600;
}
@media (max-width: 600px) {
  .text-title {
    font-size: 30px;
    font-weight: 600;
  }
}

.span-text {
  font-size: 20px;
}
@media (max-width: 600px) {
  .span-text {
    font-size: 15px;
  }
}
.text-margin {
  width: 90%;
}

.req-and-op {
  width: 90%;
  display: flex;
  justify-content: flex-start;
}
.card-Attributies {
  box-shadow: -6px 5px 15px -5px #000;
  background-color: inherit !important;
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  margin: 1.6%;
  position: relative;
  border-radius: 10px;
}

@media (max-width: 480px) {
  .card-Attributies {
    align-items: center;
    box-shadow: -6px 5px 15px -5px #000;
    width: 90%;
  }
}

.card-Attributies .card-body {
  text-align: center;
  background-color: #e6e6e6 !important;
  margin: 15px;
  border-radius: 10px;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
}
.card-Attributies .card-body-dark {
  text-align: center;
  background-color: #03283a !important;
  margin: 15px;
  border-radius: 10px;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
  color: white !important;
}

.red-cir {
  width: 20px;
  height: 20px;
  background-color: #e53838;
  border-radius: 50%;
}
.Required {
  color: #e53838;
}
.yellow-cir {
  width: 20px;
  height: 20px;
  background-color: #dba42a;
  border-radius: 50%;
}
.Optional {
  color: #dba42a;
}

.card-component {
  position: relative;
  width: 96%;
}

.ReactModal__Overlay {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
